<main role="main">
  <div _ngcontent-adc-c60="" class="editorial_contenu declaration">
    <app-retour-accueil></app-retour-accueil>
    <h3 _ngcontent-adc-c60="" id="h5o-2">Aide en ligne pour le service de découverte</h3>
    <p _ngcontent-adc-c60="">
      Le service de découverte permet de se connecter au travers d'un canal sécurisé à une ressource pédagogique et de s'authentifier avec un compte élève ou un compte agent.
    </p>
    <br _ngcontent-adc-c60="">
    <h4 _ngcontent-adc-c60="">Première visite </h4>
    Vous devez indiquer au service de découverte :
    <ul _ngcontent-adc-c60="">
      <li _ngcontent-adc-c60="">Votre profil (élève ou agent) ; sélectionnez le choix correspondant ; </li>
      <li _ngcontent-adc-c60="">Votre établissement ou école ; vous pouvez filtrer la liste :
        <ul _ngcontent-adc-c60="">
          <li _ngcontent-adc-c60="">en choisissant d'abord l’école ou le type d'établissement : école, collège, lycée ou autre, </li>
          <li _ngcontent-adc-c60="">en indiquant le code postal ou le nom de votre commune : saisissez les premiers caractères pour voir s’afficher une liste déroulante, puis sélectionnez le lieu,  </li>
          <li _ngcontent-adc-c60="">en choisissant enfin votre école ou établissement : sélectionnez son nom dans la liste.  </li>
        </ul>
      </li>
    </ul>
    <p _ngcontent-adc-c60="">
      Vous pouvez enregistrer votre choix pour éviter de ressaisir ces informations lors de votre prochaine visite : cochez la case si c'est votre choix.
    </p>
    <p _ngcontent-adc-c60="">
      Vous pouvez alors vous connecter via votre ENT, ÉduConnect ou votre guichet agent ; si vous n’êtes pas déjà authentifié, vous devrez saisir vos identifiant et mot de passe.
    </p>
    <p _ngcontent-adc-c60="">
      Si ceux-ci sont valides, vous serez redirigé vers votre ressource en toute transparence.
    </p>
    <br _ngcontent-adc-c60="">
    <h4 _ngcontent-adc-c60="">Visites suivantes </h4>
    <p _ngcontent-adc-c60="">
      Lors de votre prochaine visite, si vous avez choisi de mémoriser vos précédents choix, vous pouvez choisir école ou votre établissement parmi une liste de choix.
    </p>
    <p _ngcontent-adc-c60="">
      Si votre établissement ou école n’est pas proposé, vous pouvez rechercher à nouveau votre établissement.
    </p>
    <p _ngcontent-adc-c60="">Si vous choisissez d'enregistrer ce choix avant de vous connecter, il apparaîtra dans cette liste la prochaine fois (en remplacement de votre choix le plus ancien, dans la limite de vos trois derniers choix).</p>
    <p _ngcontent-adc-c60="">Pour plus d’information :</p>
    <p> <a href="https://www.education.gouv.fr/" target="_blank">Lien vers le site du ministère de l’Éducation nationale</a> </p>
    <br _ngcontent-adc-c60="">
  </div>
</main>
